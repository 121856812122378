import { ArrowHeadType, Elements, OnLoadParams } from "react-flow-renderer";
import { createStore } from "redux";

export type TData = {
  label?: string;
};

export type TStore = {
  elements: Elements<TData>;
  currentElementId?: string;
  rfInstance?: OnLoadParams;
};

/**
 * Первоначальное состояние
 */
export const initialStore: TStore = {
  elements: [
    {
      id: "1",
      type: "input",
      data: {
        label: "Start",
      },
      position: { x: 250, y: 5 },
    },
    {
      id: "2",
      type: 'default',
      data: { label: "Node 2" },
      position: { x: 100, y: 100 },
    },
    {
      id: "e1-2",
      source: "1",
      target: "2",
      type: 'floating',
      arrowHeadType: ArrowHeadType.ArrowClosed,
    },
    {
      id: "3",
      type: 'default',
      data: { label: "Node 3" },
      position: { x: 300, y: 100 },
    },
    {
      id: "4",
      type: 'default',
      data: { label: "Node 4" },
      position: { x: 300, y: 300 },
    },
    {
      id: "5",
      type: 'default',
      data: { label: "Node 5" },
      position: { x: 100, y: 300 },
    },
    {
      id: "e1-3",
      source: "1",
      target: "3",
      type: 'floating',
      arrowHeadType: ArrowHeadType.ArrowClosed,
    },
    {
      id: "e2-4",
      source: "2",
      target: "4",
      type: 'floating',
      arrowHeadType: ArrowHeadType.ArrowClosed,
    },
  ],
};

/**
 * Редьюсер
 */
export const reducer = (state: TStore = initialStore, action: any): TStore => {
  switch (action.type) {
    case "SELECT_ELEMENT":
      return {
        ...state,
        currentElementId: action.payload,
      };
    case "EDIT_ELEMENT":
      return {
        ...state,
        elements: state.elements.map((el) => {
          if (el.id === action.payload.id) {
            return {
              ...el,
              data: {
                ...el.data,
                ...action.payload.data,
              },
            };
          }
          return el;
        }),
      };
      case "TYPE_ELEMENT":
        return {
          ...state,
          elements: state.elements.map((el) => {
            if (el.id === action.payload.id) {
              return {
                ...el,
                type: action.payload.type
              };
            }
            return el;
          }),
        };
    case "ADD_ELEMENT":
      return {
        ...state,
        elements: [...state.elements, action.payload],
      };
    case "REMOVE_ELEMENT":
      return {
        ...state,
        elements: state.elements.filter(
          (element) => element.id !== action.payload
        ),
      };
    case "SET_ELEMENTS":
      return {
        ...state,
        elements: action.payload,
      };
    case "SET_INSTANCE":
      return {
        ...state,
        rfInstance: action.payload,
      };
    default:
      return state;
  }
};

/**
 * стор
 */
export const store = createStore(reducer);
