import "./Layout.css";
import "primereact/resources/themes/mdc-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { Splitter, SplitterPanel } from "primereact/splitter";
import { useDispatch, useSelector } from "react-redux";
import ReactFlow, {
  addEdge,
  ArrowHeadType,
  Connection,
  Controls,
  Edge,
  EdgeTypesType,
  Elements,
  FlowElement,
  OnLoadParams,
  removeElements,
  updateEdge,
} from "react-flow-renderer";
import { TStore } from "../../store";
import { ElementEditor } from "../ElementEditor/ElementEditor";
import { useCallback } from "react";
import { Header } from "../Header/Header";
import FloatingEdge from "../FloatingEdge/FloatingEdge";
import FloatingConnectionLine from "../FloatingConnectionLine/FloatingConnectionLine";
import GreatNode from "../GreatNode/GreatNode";

const edgeTypes: EdgeTypesType = {
  floating: FloatingEdge,
};

export const nodeTypes = {
  great: GreatNode,
};

/**
 * Главный экран
 */
export const Layout = () => {
  const elements = useSelector((store: TStore) => store.elements);
  const currentElementId = useSelector(
    (store: TStore) => store.currentElementId
  );
  const element = elements.find((el) => el.id === currentElementId);

  const dispatch = useDispatch();

  console.log("Layout render", currentElementId);

  /**
   * Загрузка диаграммы
   */
  const handleLoad = useCallback(
    (rfInstance: OnLoadParams) => {
      dispatch({ type: "SET_INSTANCE", payload: rfInstance });
      rfInstance.fitView();
    },
    [dispatch]
  );

  /**
   * Выделение элемента диаграммы
   */
  const handleElementClick = useCallback(
    (_event: React.MouseEvent, element: FlowElement) => {
      dispatch({ type: "SELECT_ELEMENT", payload: element.id });
    },
    [dispatch]
  );

  /**
   * Соединение связью двух элементов диаграммы
   */
  const handleConnect = useCallback(
    (params: Edge | Connection) => {
      dispatch({
        type: "SET_ELEMENTS",
        payload: addEdge(
          { ...params, arrowHeadType: ArrowHeadType.ArrowClosed, type: 'floating' },
          elements
        ),
      });
    },
    [dispatch, elements]
  );

  /**
   * Изменение связи
   */
  const handleEdgeUpdate = useCallback(
    (oldEdge, newConnection) => {
      dispatch({
        type: "SET_ELEMENTS",
        payload: updateEdge(oldEdge, newConnection, elements),
      });
    },
    [dispatch, elements]
  );

  /**
   * Удаление элементов диаграммы
   */
  const handleElementsRemove = useCallback(
    (elementsToRemove: Elements) => {
      dispatch({
        type: "SET_ELEMENTS",
        payload: removeElements(elementsToRemove, elements),
      });
    },
    [dispatch, elements]
  );

  /**
   * Element Editor
   */
  const handleSetLabel = useCallback(
    (l) => {
      dispatch({
        type: "EDIT_ELEMENT",
        payload: {
          id: currentElementId,
          data: { label: l || "" },
        },
      });
    },
    [dispatch, currentElementId]
  );

  const handleSetType = useCallback(
    (l) => {
      console.log('>>', l)
      dispatch({
        type: "TYPE_ELEMENT",
        payload: {
          id: currentElementId,
          type: l || "default",
        },
      });
    },
    [dispatch, currentElementId]
  );

  const handleDelete = useCallback(() => {
    const elementsToRemove = [
      element,
      elements.find((el) => element?.id === (el as Edge).source),
      elements.find((el) => element?.id === (el as Edge).target),
    ] as Elements;

    dispatch({
      type: "SET_ELEMENTS",
      payload: removeElements(elementsToRemove.filter(Boolean), elements),
    });
  }, [dispatch, element, elements]);

  return (
    <div className="page">
      <div className="header">
        <Header />
      </div>
      <div className="body">
        <Splitter>
          <SplitterPanel size={70} minSize={50}>
            <ReactFlow
              elements={elements as any}
              defaultZoom={1}
              minZoom={0.5}
              maxZoom={2}
              onElementClick={handleElementClick}
              onLoad={handleLoad}
              onConnect={handleConnect}
              onEdgeUpdate={handleEdgeUpdate}
              onElementsRemove={handleElementsRemove}
              edgeTypes={edgeTypes}
              connectionLineComponent={FloatingConnectionLine}
              snapToGrid={true}
              nodeTypes={nodeTypes}
            >
              <div className="inner"></div>
              <Controls />
            </ReactFlow>
          </SplitterPanel>
          <SplitterPanel size={30} minSize={20}>
            {currentElementId && (
              <ElementEditor
                id={currentElementId}
                label={element?.data?.label || ""}
                setLabel={handleSetLabel}
                type={element?.type || 'default'}
                setType={handleSetType}
                onDelete={handleDelete}
              />
            )}
          </SplitterPanel>
        </Splitter>
      </div>
    </div>
  );
};
