import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from 'primereact/dropdown';

import "./ElementEditor.css";

export type ElementEditorProps = {
  id: string;
  type?: string;
  setType: (type: string) => void;
  label: string;
  setLabel: (label: string) => void;
  onDelete: () => void;
};

export const ElementEditor = ({
  id,
  type = 'default',
  setType,
  label,
  setLabel,
  onDelete,
}: ElementEditorProps) => {
  /**
   * Редактируем заголовок
   */
  const handleEditLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLabel(event.target.value);
  };

  const onChange = (e: { value: any}) => {
    console.log(e)
    setType(e.value);
}

  return (
    <div className="editor">
      <div className="id">ID: {id}</div>
      <div className="label p-field">
        <label htmlFor="label" className="p-d-block">
          Label:
        </label>
        <br />
        <InputText
          id="label"
          value={label}
          className="p-d-block p-inputtext-sm"
          onChange={handleEditLabel}
        />
      </div>
      <div className="label p-field">
        <label htmlFor="label" className="p-d-block">
          Type:
        </label>
        <br />
        <Dropdown
          value={type}
          options={[
            {label: 'input', value: 'input'},
            {label: 'output', value: 'output'},
            {label: 'default', value: 'default'},
            {label: 'great', value: 'great'},
          ]}
          onChange={onChange}
          optionLabel="label"
          placeholder="Select a type"
        />
      </div>
      <hr />
      <Button label="Delete" className="p-button-danger" onClick={onDelete} />
    </div>
  );
};
