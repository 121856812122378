import { memo } from "react";
import { Handle, NodeProps, Position } from "react-flow-renderer";
import './GreatNode.css';

const GreatNode =  memo(({ data, isConnectable }: NodeProps) => {
    return (
      <>
        <Handle
          type="source"
          position={Position.Top}
          id="a"
          style={{ top: '0px', border: '1px solid #666', background: 'transparent' }}
          isConnectable={true}
        />
        <div className="node">{data.label}</div>
        <Handle
          type="target"
          position={Position.Bottom}
          style={{ bottom: -5, background: '#555' }}
          onConnect={(params) => console.log('handle onConnect', params)}
          isConnectable={true}
        />
      </>
    );
  });

  export default GreatNode;