import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Layout } from "./components/Layout/Layout";
import PrimeReact from "primereact/api";
import { Provider } from "react-redux";
import { store } from "./store";
import { ReactFlowProvider } from "react-flow-renderer";

PrimeReact.ripple = true;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactFlowProvider>
        <Layout />
      </ReactFlowProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
