import logo from "./logo.svg";
import "./Logo.css";

export const Logo = () => {
  return (
    <div className="logo">
      <img src={logo} alt="Requireum" />
    </div>
  );
};
