import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Editor } from "primereact/editor";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { Menubar } from "primereact/menubar";
import { useCallback, useState } from "react";
import { Edge, Node } from "react-flow-renderer";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { TStore } from "../../store";
import { MenuItem } from "primereact/menuitem";
import { Logo } from "./Logo/Logo";

export const Header = () => {
  const [dialogAddNode, setDialogAddNode] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [label, setLabel] = useState("");

  const dispatch = useDispatch();

  const elements = useSelector((state: TStore) => state.elements);
  const rfInstance = useSelector((state: TStore) => state.rfInstance);

  /**
   * Открытие диалога
   */
  const handleShowDialogAddNode = useCallback(() => {
    setDialogAddNode(true);
  }, []);

  const handleShowDialogOpen = useCallback(() => {
    setDialogOpen(true);
  }, []);

  /**
   * Закрытие диалога
   */
  const handleHideDialogAddNode = useCallback(() => {
    setDialogAddNode(false);
  }, []);

  const handleHideDialogOpen = useCallback(() => {
    setDialogOpen(false);
  }, []);

  /**
   * Добавляем элемент на диаграмму
   */
  const handleAddNode = useCallback(() => {
    dispatch({
      type: "ADD_ELEMENT",
      payload: {
        id: uuidv4(),
        type: 'default',
        data: { label },
        position: { x: 100, y: 10 },
      },
    });
    setDialogAddNode(false);
  }, [dispatch, label]);

  /**
   * Новый документ
   */
  const handleNewDocument = useCallback(() => {
    dispatch({ type: "SET_ELEMENTS", payload: [] });
  }, [dispatch]);

  /**
   * Загружаем и парсим JSON
   */
  const handleOpenDocument = useCallback(
    (e) => {
      const f = e.files[0];
      f.text().then((c: string) => {
        const flow = JSON.parse(c);
        dispatch({ type: "SET_ELEMENTS", payload: flow.elements || [] });
        rfInstance?.fitView();
      });
      setDialogOpen(false);
    },
    [dispatch, rfInstance]
  );

  /**
   * Сохраняем документ в JSON
   */
  const handleSaveDocument = useCallback(() => {
    if (rfInstance) {
      const uriContent =
        "data:application/octet-stream," +
        encodeURIComponent(JSON.stringify(rfInstance.toObject()));
      window.open(uriContent, "_blank");
    }
  }, [rfInstance]);

  /**
   * Menu
   */
  const menubar: MenuItem[] = [
    {
      key: "file",
      label: "File",
      icon: "pi pi-fw pi-file",
      items: [
        {
          key: "file-new",
          label: "New",
          icon: "pi pi-fw pi-plus",
          command: handleNewDocument,
        },
        {
          key: "file-open",
          label: "Open",
          icon: "pi pi-fw pi-folder-open",
          command: handleShowDialogOpen,
        },
        {
          key: "file-save",
          label: "Save",
          icon: "pi pi-fw pi-save",
          command: handleSaveDocument,
        },
      ],
    },
    {
      key: "node-add",
      label: "Add Node",
      icon: "pi pi-fw pi-sitemap",
      command: handleShowDialogAddNode,
    },
  ];

  return (
    <div className="header">
      <div className="menubar">
        <Menubar model={menubar} start={<Logo />} />
      </div>
      {/**
       * Диалоговое окно добавления элемента
       */}
      <Dialog
        header="Add Node"
        visible={dialogAddNode}
        modal
        onHide={handleHideDialogAddNode}
      >
        <div className="p-field">
          <label htmlFor="label" className="p-d-block">
            Label:&nbsp;
          </label>
          <br />
          <InputText
            id="label"
            className="p-d-block"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
          />
        </div>
        <hr />
        <Button label="Add" onClick={handleAddNode} />
        &nbsp;
        <Button label="Cancel" onClick={handleHideDialogAddNode} />
      </Dialog>
      {/**
       * Диалоговое окно открытия документа
       */}
      <Dialog
        header="Open document"
        visible={dialogOpen}
        modal
        onHide={handleHideDialogOpen}
      >
        <FileUpload mode="basic" onSelect={handleOpenDocument} />
      </Dialog>
    </div>
  );
};
